<template>
  <UiPopup
    :model-value="modelValue"
    title="Lost lead"
    description="Lead will be removed from your pipeline within 2 weeks."
    primary-button-text="Close lead"
    :loading="loading"
    secondary-button-text="Cancel"
    size="small"
    @update:model-value="emits('update:modelValue', false)"
    @confirm="submit"
  >
    <form ref="form" @submit.prevent>
      <UiInputSelect
        v-model="data.pipeline_step_id"
        :items="reasonsItems"
        name="Reason"
        placeholder="Choose a reason"
        class="mb-4 mt-2"
        :error="useGetFieldErrors(v$, ['pipeline_step_id'])"
      />
      <UiInputTextField v-model="data.comment" name="Other" placeholder="Or add other reason" class="mb-4" />
    </form>
  </UiPopup>
</template>

<script setup lang="ts">
import { required, helpers } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import type { Stage, Lead, InputItem } from '@/types'
import { useUiStore } from '~/store/ui'
import { CLOSE_STEPS } from '@/constants'

const uiStore = useUiStore()

const emits = defineEmits(['update:modelValue', 'input'])

type Props = {
  modelValue: boolean
  lead: Lead
  stage: Stage
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
})

const loading = ref(false)

const data = ref({
  pipeline_stage_id: props.stage?.id,
  pipeline_step_id: null,
  comment: '',
})

const reasonsItems: InputItem[] =
  props.stage.steps
    ?.filter((s) => s.code !== CLOSE_STEPS.WON && s.name !== 'Other')
    .map((r) => ({ value: r.id, text: r.name })) || []

const rules = computed(() => ({
  pipeline_step_id: { required: helpers.withMessage('The reason is required', required) },
}))

const v$ = useVuelidate(rules, data)

const submit = async () => {
  const isValid = await v$.value.$validate()
  if (!isValid) return

  loading.value = true
  try {
    const response = await useCloseLead(props.lead.id, data.value)
    uiStore.showSnackBanner(response.message)

    emits('update:modelValue', false)
    emits('input', response.data)
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  } finally {
    loading.value = false
  }
}
</script>

<style scoped></style>
